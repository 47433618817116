.header {
  background-color: #101010;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px,
    rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  width: 100%;
  padding: 0 2em 0 2em;
}

header .logo {
  color: #fff;
  font-size: 35px;
  cursor: pointer;
  letter-spacing: 1px;
}

header .logo:hover {
  color: #fff;
}

nav .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.links-navbar {
  display: flex;
  justify-content: right;
}

.header .navlink {
  color: #fff !important;
  font-weight: bold;
  text-transform: capitalize;
  margin-left: 20px;
  text-transform: uppercase;
  transition: transform 0.4s;
  text-align: end;
}

.header .navlink:hover {
  color: #dc143c !important;
  transform: scale(1.1);
}

.header .navlink:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #dc143c;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.header .navlink:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
